import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import JasaReducer from '../reducers/reducer';

export default createStore(
    JasaReducer,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
)