import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {toggleNavigation} from '../reducers/reducer'
import Vid1 from '../assests/videos/sock-anim-no-zoom.webm'
import Gif1 from '../assests/videos/sock-anim-no-zoom-gif.gif'
import '../style/components/VideoComponent.css';


export class VideoComponent extends Component {

    componentDidMount() {
        document.getElementById('jasa-video').play();
    }

    render() {
        return (
            <Fragment>
                <div id={'videoComp'} className={'video-component'}>
                    <video autoPlay loop muted className={'video'} id={'jasa-video'}>
                        <source src={Vid1} type={'video/webm'}/>
                    </video>
                    <img src={Gif1} alt={'video-gif'} className={'video-gif'}/>
                </div>
            </Fragment>
        )
    }
}


const mapStateToProps = ({navigateComponent}) => {
    return {
        navigateComponent: navigateComponent,

    }
};

export default connect(mapStateToProps, {toggleNavigation})(VideoComponent);