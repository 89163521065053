import React from 'react';

import './styles/ToogleButton.css'

const ToogleButton = props => (
    <button className = "toogle-button" onClick = {props.drawerClickHandler}>
        <div className = "toogle-button__line"></div>
        <div className = "toogle-button__line"></div>
        <div className = "toogle-button__line"></div>
    </button>
)

export default ToogleButton;