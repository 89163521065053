import React from 'react';
import './styles/UserTestingContent.css';
import Sock1 from '../../assests/images/socks/sock-jasa3.png'
import Sock2 from '../../assests/images/socks/sock-jasa1.png'
import oldLady from '../../assests/images/old-lady.png'

const UserTestingContent = props => {
    return (
        <div className="user-testing-content-main">
            <div className="images-para">
                <div className="text-content">
                    <div className="content-box">
                        <h2>Åpen & hvilemodus</h2>
                        <img src={Sock1} alt="sock1"/>
                    </div>
                    <div className="content-box">
                        <h2>Lukket & aktivert</h2>
                        <img src={Sock2} alt="sock2"/>
                    </div>
                </div>
                <div className="paragraph">
                    <p>JASA-strømpen er utviklet for å være verdens enkleste
                        kompresjonsstrømpe å bruke gjennom en unik og patentert
                        glidelåsfunksjon. JASA-strømpen trekkes enkelt på og av
                        med glidelåsen trukket ned, og aktiveres ved lukket
                        glidelås. Bak glidelåsen er et beskyttende lag mot huden.
                        Kompresjonsstrømpen er 3D strikket i elastiske kvalitetsmaterialer for optimal passform.
                    </p>
                </div>
            </div>
            <div className="bottom-content">
                <div className='main-col'>
                    <div className="col-1">
                        <p>«Denne strømpen er blitt uunnværlig for meg»<br/><b> Dame, 60 år </b></p>
                        <p>«JASA-strømpen har vært en livsforbedrende endring for meg» <br/> <b>Mann, 45 år </b></p>

                    </div>
                    <div className="col-2">
                        <p>«Med JASA-strømpen trenger jeg ikke lenger å be
                            datteren min om å hjelpe meg på og av med kompresjonsstrømpen»
                            <br/><b>Dame, 50 år</b>
                        </p>
                        <p>«Støttestrømper kan være vanskelige å få på og av, og i mange tilfeller er
                            pasientens mulighet til å gjøre dette selv begrenset.
                            Dette gir store kostnader for oss og ubehag for brukerne våre.»
                            <br/><b>Helsearbeider, Stavanger kommune</b>
                        </p>
                    </div>
                </div>
                <div className="col-3">
                    <img src={oldLady} alt={'old-lady'}/>
                </div>
            </div>
        </div>
    );
};

export default UserTestingContent;