import React, {Component} from 'react';
import '../style/components/Partners.css'

class Partners extends Component {
    render() {
        return (
            <div className={'partners-main'}>
                <div className={'partners-h1'}>
                    <h1>Samarbeidspartnere </h1>
                </div>
                <div className={'partners-row'}>
                    <img src={require('../assests/images/partners/Innovasjonsparkenlogo.png')}
                         alt={'innovasjonspark'}/>
                    <img src={require('../assests/images/partners/NSCC_Logo.png')}
                         alt={'norwegian smart care cluster'}/>
                    <img src={require('../assests/images/partners/pm_logo.png')} alt={'peter muller'}/>

                    <img  src={require('../assests/images/partners/Valide.png')}
                         alt={'innovasjon norge'}/>
                    <img  src={require('../assests/images/partners/sintef.png')}
                         alt={'innovasjon norge'}/>
                    <img src={require('../assests/images/partners/Utvik.jpg')}
                         alt={'forskning og utvikling'}/>
                    <img src={require('../assests/images/partners/logo-and-Nansen-&-Co.jpg')}
                         alt={'nansen and co'}/>
                </div>
            </div>
        )
    }
}

export default Partners;
