import React from 'react';
import './styles/SideDrawer.css'

const sideDrawer = props => {
    let drawerClasses = 'side-drawer'
    if (props.show) {
      drawerClasses = 'side-drawer open'
    }
    return (
      <div className={drawerClasses}>
        <div className = "list-items">
          <ul className = "menu-list">
            <li onClick = { props.userTesting } > Jasa-strømpen </li>
            <li onClick = { props.contact } > Kontakt oss </li>
            <li onClick = {props.about }> Om oss </li>
          </ul>
        </div>
      </div>
    )
  }

export default sideDrawer;