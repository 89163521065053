import React from 'react';
import ContactFrom from '../components/Utilities/ContactForm'
import "../style/components/Contact.css"

class Contact extends React.Component {
    
    render(){
        let contactDetails
        let contactClasses = "contact"
        if (this.props.show){
            contactDetails = <ContactFrom close = {this.props.closeButton} />;
            contactClasses = "contact contact-open"
            }
        return ( 
            <div className = {contactClasses}>
            <h1>Kontakt oss</h1>
            <p>Vi ønsker veldig gjerne å snakke med dere.</p>
            <p>Ønsker du å være med på brukertesting, gå til Brukertesting. Gjelder det annet, 
            fyll inn i kontaktskjema under og vi svarer normalt innen ett virkedøgn. </p>
            {contactDetails}
            </div>
            )
    }
}

export default Contact;