import React, {Component} from "react";
import MainSock from '../assests/images/socks/sock1.png';
import SecondSock from '../assests/images/socks/sock2.png';
import ThirdSock from '../assests/images/socks/sock3.png';
import FourSock from '../assests/images/socks/sock4.png'
import {ReactComponent as Logo} from '../assests/images/see-more.svg';
import '../style/components/Home.css';
import {connect} from "react-redux";
import {toggleTestingForm, toggleTestingShow} from '../reducers/reducer'

const sockImage = [MainSock, SecondSock, ThirdSock, FourSock];

class Home extends Component {


    testingFormHandler = () => {
        this.props.toggleTestingForm();
    };

    render() {
        return (

            <div className={'div-home-page'} onWheel={(e)=>{
               // e.preventDefault();
                console.log('hey im wheeling')
            }}>
                <div className={'sock-beige-toe1-text'}>
                    <h2 className={'home-h2'}>
                        Enklere.<br/>
                        Raskere.<br/>
                        Mer frihet.
                    </h2>
                    <p className={'home-text'}> JASA-strømpen utgjør en forskjell </p>
                    <h3 className={'home-h3'} onClick={(e) => {
                        e.preventDefault();
                        this.props.toggleTestingShow();
                    }}>
                        Se hvordan
                        <Logo className={'see-more-svg'}/>
                    </h3>
                    <div className={'button-bestill'}>
                        <button onClick={this.testingFormHandler}>
                            Bestill nå
                        </button>
                    </div>
                </div>
                <div className={'sock-beige-toe1'} id="cf">
                    <img className="top sock-beige-toe1-img" src={sockImage[0]}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({Footer}) => {
    return {
        Footer: Footer
    }
};

export default connect((mapStateToProps), {
    toggleTestingForm, toggleTestingShow
})(Home);