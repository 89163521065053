import React, {Component} from 'react';
import TestingFormContent from './Utilities/TestingFormContent'
import '../style/components/TestingForm.css'

class TestingForm extends Component {
    state = {
        showSizeChart: false,
        sizeChartText: ''
    }
    openSizeChart = () =>{
        this.setState(prevState => {
            return { 
                showSizeChart: !prevState.showSizeChart 
            }
        })
    }
    
    render() {
        let testingFormContent;
        let testingClasses = "testing-main";
        let sizeChart;
        this.state.sizeChartText = "Størrelsesguide"
        if(this.state.showSizeChart){
            sizeChart =  <img  className = "size-chart" src= {require('../assests/images/size-chart.png')} alt = "size chart"/> ;
            this.state.sizeChartText = "Close size Chart"
        }
        if (this.props.show) {
            testingFormContent = <TestingFormContent close={this.props.closeButton}/>;
            testingClasses = "testing-main testing-open"
        }
        return (
            <div className={testingClasses}>
                <div className="textual-content">
                    <p>Vi har dessverre ikke kompresjonsstrømper for salg enda, men vi gjennomfører løpende brukertesting. Den siste
                        versjonen vi har produsert viser svært lovende resultater.</p>
                    <h2 style = {{marginBottom: '0', paddingBottom: '0'}}>Brukertesting</h2>
                    <p style = {{marginTop: '0', paddingTop: '0'}}>Vi jobber hver dag for å utvikle verdens mest brukervennlige kompresjonsstrømpe.
                        JASA-strømpen utvikles gjennom brukerorientert innovasjon. Vi gjennomfører derfor
                        løpende brukertester med brukere i ulike kategorier og i tett samarbeid med helsepersonell
                        og forskningsinstitusjoner.</p>
                    <p>Prototypen av JASA-strømpen er i dag i kompresjonsklasse 2, men Jasa Medical vil utvikle
                        en klasse 3 i tillegg til øvrig kompresjonsprodukter med samme teknologi. </p>
                    <p style={{fontWeight: 'bold', paddingBottom: '0', marginBottom: '0'}}> Bli med på testingen og vær blant de første
                        brukerne av JASA-strømpen</p>
                    <p style = {{ marginTop:'0', paddingTop: '0'}}>Er du interessert i å prøve ut JASA-strømpen skriv inn i kontaktskjema under.</p>
                    <p>Finn din størrelse ved å se på instruksjoner og størrelsesguide. </p>
                    <button onClick = {this.openSizeChart}> {this.state.sizeChartText} </button>
                    
                </div>
                {sizeChart}
                {testingFormContent}

            </div>

        );
    }
}

export default (TestingForm);