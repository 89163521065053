import React, {Component} from 'react';
import linkedIn from '../assests/images/linkedIn.svg';
import facebook from '../assests/images/facebook.svg';
import '../style/components/Footer.css'
import Partners from "./Partners";

class Footer extends Component {
    render() {
        return (
            <footer id={'foot'} className={'footer'}>
                <Partners/>
                <div className={'footer-content'}>
                    <div className={'column'}><h3 className={'footer-heading'}>Adresse</h3>
                        <p className={'footer-text'}> Jens Zetlitz' gate 25
                            <br/>
                            4008 Stavanger
                            <br/>
                            <br/>
                            Email: post@jasamedical.com
                            <br/>
                            Telefon: 952 61 089</p>
                    </div>
                    <div className={'subscribe'}><h3>Abonner på våre nyhetsbrev</h3>
                        <form>
                            <input className={'footer-input'} type={'email'} name={'email'}
                                   placeholder={'Fyll ut email her'}/>
                            <button className={'footer-button'}>Gi meg oppdateringer</button>
                        </form>
                        <div className={'footer-social-icons'}>
                            <a href={'https://www.facebook.com/'} target={'blank'}>
                                <img src={facebook} alt={'facebook'} /></a>
                            <a href={'https://www.linkedin.com/'} target={'blank'}>
                                <img src={linkedIn} alt={'linkedIn'}/></a>
                        </div>
                    </div>
                </div>
                    <div className={'copyright'}>
                        <p>© {new Date().getFullYear()} by Jasa Medical AS.</p>
                    </div>
            </footer>
        )
    }
}

export default Footer;