import React from 'react';
import './styles/AboutContent.css';
import {connect} from "react-redux";
import {toggleTestingForm} from "../../reducers/reducer";

class AboutContent extends React.Component {
    render() {


        return (

            <div className={'content'}>
                <h1 className={'heading'}>Om oss</h1>
                <p>Jasa Medical er et helseteknologiselskap fra Stavanger
                    som gjennom brukerorientert innovasjon, ny produksjonsteknologi
                    og -tekstiler har utvikler en revolusjonerende kompresjonsstrømpe.</p>
                <h3 className={'mission'}>Misjon</h3>
                <p className={'mission-text'}>"Forbedre livskvaliteten til kompresjonsstrømperbrukere,
                    øke selvhjulpenhet og redusere tap av folkehelse gjennom rett og økt bruk."</p>
                <h3 className={'heading'}>Status</h3>
                <p>Vi har gjennomført flere brukertester av prototyper med påfølgende produktforbedringer.
                    Testene har gitt svært positive resultater. Selskapet jobber nå med å utvikle en kommersiell
                    kompresjonsstrømpe. Dette gjøres i tett samarbeid med Jasa Medicals støttespillere og partnere.</p>

                <p>Om du ønsker å lære mer om Jasa Medicals brukertesting eller ønsker selv å delta, klikk på knappen
                    under
                    for mer informasjon:</p><br/>
                <button className={'content-button'} onClick={(e) => {
                    e.preventDefault();
                    this.props.toggleTestingForm()
                }}>
                    Gå til brukertesting
                </button>

                <h3 className={'heading'}>Starten på Jasa Medical</h3>
                <p className={'quote'}>"To ganger hver dag, morgen og kveld,
                    kommer det en hjemmesykepleier hjem til meg for å ta på og av kompresjonsstrømpe på meg.
                    Hun kjører flere kilometer to ganger daglig til meg. Hver dag bruker hun (hjemmesykepleieren) over
                    én
                    time på meg.
                    Jeg trenger ikke hjelp til annet [enn strømpen].<br/>
                    <br/>
                    Problemet mitt er at jeg alltid våkner lenge før hun kommer hjem til meg.
                    Dette betyr at jeg hver dag må ligge lenge i sengen min og vente på at hun kommer før jeg står opp,
                    fordi jeg må ha på kompresjonsstrømpen når jeg står og går.
                    Det å ha på en kompresjonsstrømpe gjennom en hel dag er ubehagelig, og jeg klarer ikke ta av
                    strømpen.
                    Det finnes heller ikke mekanismer som gjør at jeg kan lette på trykket i løpet av dagen.”</p>
                <p>Jasa Medical ble startet fordi dagens løsninger ikke ble vurdert som brukervennlige nok.
                    Dagens alternativer er problematiske å ta av og på og krever vesentlig med muskelkraft og/eller
                    hjelpemidler.
                    Dette problemet resulterer ofte i redusert livskvalitet ved i mange tilfeller redusert
                    selvhjulpenhet,
                    smerter og eskalering av problem på grunn av for lite bruk. Videre utgjør problemet vesentlige
                    belastninger for pårørende,
                    for helsepersonell i form av både belastningsskader og ressursknapphet, nasjonale helsebudsjetter
                    med
                    mer.</p>
                <p>Selskapets drivkraft til å utvikle en forbedret kompresjonsstrømpe er først og fremst å gi et
                    vesentlig
                    mer brukervennlig alternativ til brukere og pasienter, og dernest å redusere unødvendig ressursbruk
                    for
                    helsepersonell,
                    samt redusere den samfunnsøkonomiske belastningen som en konsekvens av dagens fravær av gode
                    alternativer.
                    Kostnader assosiert med dette i Norge beløper seg til mange milliarder. Det er et globalt
                    problem.</p>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        testingForm: state.testingForm,
    }
};
export default connect(mapStateToProps, {
    toggleTestingForm
})(AboutContent);