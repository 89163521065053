import React, {Fragment} from 'react';
import Nav from './components/Nav'
import Home from "./components/Home";
import VideoComponent from "./components/VideoComponent";
import Footer from "./components/Footer";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faTimesCircle, faArrowAltCircleUp, faAngleDoubleDown} from '@fortawesome/free-solid-svg-icons';
import {connect} from "react-redux";
import {toggleNavigation} from "./reducers/reducer";
import './App.css'

library.add(faTimesCircle, faArrowAltCircleUp, faAngleDoubleDown);


class App extends React.Component {
    state = {
        scrolled: false,
        footerScroll: false,
    }

    componentDidMount() {
        window.addEventListener('scroll',() => {
            const isTop = window.scrollY > 40;
            if (!isTop) {
                this.setState({scrolled: false})
            } else {
                this.setState({ scrolled: true })
            }
           
        });
    }

    render() {
        let currentView = <Home/>;
        let foot;
        if(this.state.scrolled){
            currentView = <VideoComponent />;
            foot = <Footer />
        }
        if(this.state.footerScroll){

        }
        return (
            <Fragment>
                <div className="App" >
                    <header>
                        <Nav/>
                    </header>
                </div>
                <main>
                   {currentView}
                </main>
                {foot}
            </Fragment>
        )
    }
};



export default (App);
