import React, {Component} from 'react';
import '../style/components/Nav.css';
import ToogleButton from './Utilities/ToogleButton';
import SideDrawer from './Utilities/SideDrawer';
import Backdrop from './Utilities/Backdrop';
import About from './About';
import Contact from './Contact';
import UserTesting from './UserTesting';
import {connect} from "react-redux";
import {toggleTestingForm,toggleTestingShow} from "../reducers/reducer";
import TestingForm from './TestingForm';


class Nav extends Component {
    state = {
        sideDrawerOpen: false,
        showAbout: false,
        contactShow: false,
        //testingShow: false,

    };

    drawerToggleClickHandler = () => {
        this.setState(prevState => {
            return {sideDrawerOpen: !prevState.sideDrawerOpen}
        })

    };

    backdropClickHandler = () => {
        this.setState({sideDrawerOpen: false, contactShow: false});
        if (this.props.testingForm)
            this.props.toggleTestingForm();
    };

    showAboutContent = () => {
        if (this.props.testingShow)
            this.props.toggleTestingShow();
        this.setState(prevState => {
            return {
                showAbout: !prevState.showAbout,
                contactShow: false,
                sideDrawerOpen: false,

            }
        })
    };
    closeAboutHandler = () => {
        this.setState({showAbout: false})
    };
    showContactForm = () => {
        this.setState(prevState => {
            return {
                contactShow: !prevState.contactShow,
                sideDrawerOpen: false,

            }
        })
    };
    closeContactFormHandler = () => {
        this.setState({contactShow: false})
    };
    showUserTesting = () => {
        this.props.toggleTestingShow();
        this.setState(prevState => {
            return {
                showAbout: false,
                contactShow: false,
                sideDrawerOpen: false,
            }
        })
    };
    closeUserTesting = () => {
        this.props.toggleTestingShow();
    };

    render() {
        let backdrop;

        if (this.state.sideDrawerOpen || this.state.contactShow || this.props.testingForm) {
            backdrop = <Backdrop click={this.backdropClickHandler}/>;
        }

        return (
            <div className="Nav">
                <ToogleButton drawerClickHandler={this.drawerToggleClickHandler}/>
                <SideDrawer
                    show={this.state.sideDrawerOpen}
                    about={this.showAboutContent}
                    contact={this.showContactForm}
                    userTesting={this.showUserTesting}
                />
                {backdrop}
                <TestingForm show={this.props.testingForm}/>
                <Contact show={this.state.contactShow} closeButton={this.closeContactFormHandler}/>
                <About show={this.state.showAbout} closeButton={this.closeAboutHandler}/>
                <UserTesting show={this.props.testingShow} closeButton={this.closeUserTesting}/>
                <img
                    onClick={(e) => {
                        e.preventDefault();
                        if(this.props.testingShow)
                            this.props.toggleTestingShow();
                        this.setState({showAbout:false});
                        window.scrollTo(0, 0);
                    }}
                    className="nav-logo"
                    src={require('../assests/images/Logo.png')}
                    alt="Jasa Medical"
                    height = "60vh"

                />
                <div className="spacer"/>
                <div className="nav-bar-items">
                    <ul>
                        <li onClick={this.showUserTesting}>Jasa-strømpen</li>
                        <li onClick={this.showContactForm}>Kontakt oss</li>
                        <li onClick={this.showAboutContent}>Om oss</li>
                    </ul>
                </div>


            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        testingForm: state.testingForm,
        testingShow: state.testingShow

    }
};
export default connect(mapStateToProps, {
    toggleTestingForm,toggleTestingShow
})(Nav);
