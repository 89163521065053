import React, {Fragment} from 'react';
import UserTestingContent from '../components/Utilities/UserTestingContent'
import '../style/components/UserTesting.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class UserTesting extends React.Component{
   render() {
    let userClasses = "user-testing";
    let userContent;
    if (this.props.show){
        userClasses = "user-testing user-testing-open";
        userContent = <UserTestingContent close = {this.props.closeButton} />
    }
    return(
        <Fragment>
            <div className={this.props.show?"button-down-to-close":'hidden'} >
            </div>
            <div className = {userClasses} >
                {userContent}
            </div>
        </Fragment>
    )
}
}
export default UserTesting;