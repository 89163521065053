import React, {Component} from 'react';
import './styles/ContactFrom.css';

class ContactForm extends Component {
    state = { 
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        message: '',
        contactType: '',
        emailError: '',
        nameError: null,
        messageError: '',
        canSubmit: false,
        error: null,
        submitted: false,
        success: false,
        
    }
    encode = data =>
    Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    validateInputs = () => {
        const { firstname, email, message } = this.state
        let canSubmit = false;
        let nameCheck = false;
        let emailCheck = false;
        let messageCheck = false;

        if(firstname.length > 2){
            nameCheck = true
            this.setState({ nameError: null })
        } else {
            this.setState({ nameError: 'Please make sure your name is correct '})
        }

        var re = /\S+@\S+\.\S+/
        if (re.test(email)) {
            emailCheck = true
            this.setState({ emailError: null })
            } else {
            this.setState({ emailError: 'Epost er ikke gyldig' })
        }

        if (message.length > 1) {
            messageCheck = true
            this.setState({ messageError: null })
          } else {
            this.setState({ messageError: 'Det mangler en beskrivelse' })
          }
        if(nameCheck && emailCheck && messageCheck){
           return(canSubmit = true)
        } else {
            return (canSubmit = false)
        }
    }
    
    handleSubmit = e => {
        e.preventDefault()
        const canSubmit = this.validateInputs()

        if(canSubmit){
            const data = {
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
                message: this.state.message,
                phoneNumber: this.state.phoneNumber,
                contactType: this.state.contactType,
            }
            fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: this.encode({ 'form-name': 'Contact-Form', ...data })
              })
              .then(response => {
                  this.setState({
                      success: true,
                      submitted: true,
                      
                  })
                  this.resetForm()
              })
              .catch(error => {
                  console.log(error)
                  this.setState({
                      success: false,
                      submitted: true,
                      message: 'Noe gikk galt. Vennligst forsøk igjen.'
                  })
              })
        }
       
    }
    resetForm = () => {
        this.setState({
          firstname: '',
          lastname: '',
          phoneNumber: '',
          email: '',
          message: '',
          canSubmit: false,
          submitted: false,
        })
      }

    render() { 
        const {
            firstname,
            lastname,
            email,
            phoneNumber,
            message,
            emailError,
            nameError,
            messageError,
            canSubmit,
            error,
            submitted,
            success,
        } = this.state

    if(!submitted && !success){
        return ( 
            <div className = "contact-box">
                <div className = "form">
                    <form
                    className = "contact-form"
                    name = "contactForm"
                    onSubmit = {this.handleSubmit}
                    data-netlify = "true"
                    method = "POST"
                    netlify = "true"
                    >
                    {nameError && (
                        <p className = "error">{nameError}</p>
                    )}
                    <input 
                    className = "line-input"
                    type = "text" 
                    placeholder = "Fornavan"
                    name = "firstname"
                    value = {firstname}
                    onChange = {this.handleChange}
                    />
                    <input 
                    className = "line-input"
                    type = "text" 
                    placeholder = "Etternavn"
                    name = "lastname"
                    value = {lastname}
                    onChange = {this.handleChange}
                    />
                    {emailError && (
                        <p className = "error">{ emailError }</p>
                    )}
                    <input 
                    className = "line-input"
                    type = "email" 
                    placeholder = "E-post"
                    name = "email"
                    value = {email}
                    onChange = {this.handleChange}
                    />
                    <input 
                    className = "line-input"
                    type = "number" 
                    placeholder = "Telefon"
                    name = "phoneNumber"
                    value = {phoneNumber} 
                    onChange = {this.handleChange}
                    />
                    {messageError && (
                        <p className = "error" >{ messageError }</p>
                    )}
                    <textarea 
                    className = "multi-line-input"
                    placeholder = "Skriv meldingen din her"
                    name = "message"
                    value = {message}
                    onChange = {this.handleChange}
                    />
                    <div className = "radio-buttons" >
                        <ul>
                            <li>
                                <label>
                                    <input
                                      type = "radio"
                                      name = "contactType"
                                      value = "Privatperson"
                                      onChange = {this.handleChange} 
                                    />
                                    Privatperson
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input
                                      type = "radio"
                                      value = "Bedrift"
                                      name = "contactType"
                                      onChange = {this.handleChange}
                                    />
                                    Bedrift
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input
                                      type = "radio"
                                      value = "Offentlig aktør"
                                      name = "contactType"
                                      onChange = {this.handleChange}
                                    />
                                    Offentlig aktør
                                </label>
                            </li>
                        </ul>
                    </div>
                    <button
                    type = "submit"  
                    onClick = {this.handleSubmit}
                    >Send</button>
                    </form>
                </div>
            </div>
        );
    } else {
        return(
            <div className={'submitted-message'}>
                <p>“Takk, du hører snart fra oss”</p>
                <button onClick = {this.props.close} > OK </button>
            </div>
        )
    }
    }
}
 
export default ContactForm;

