import React, {Fragment} from 'react';
import AboutContent from './Utilities/AboutContent'
import '../style/components/About.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


class About extends React.Component {

    render() {
        let aboutClasses = 'about';
        let aboutContent;
        if (this.props.show) {
            aboutClasses = 'about about-open'
            aboutContent = <AboutContent close={this.props.closeButton}/>;
        }
        return (
            <Fragment>

                <div className={aboutClasses}>
                    {aboutContent}
                </div>
                <div className={this.props.show?"button-up-to-close":'hidden'}>
                    <FontAwesomeIcon
                        icon="arrow-alt-circle-up"
                        size="3x"
                        color={'green'}
                        onClick={this.props.closeButton}
                    />
                </div>
            </Fragment>
        );
    }

}

export default About;