import {SHOW_JASA, showJasaTogle,
    TOGGLE_NAVIGATE,navigateTogle,
SHOW_TESTING_FORM,showTestingForm} from "../actions/actions";

const initState = {
    Footer: false,
    testingForm: false,
    navigateComponent: true,
    testingShow: false
};
//action dispatchers
export const toggleTestingShow = () => {

    return (dispatch, getState) => {
        dispatch(showJasaTogle(!getState().testingShow))
    }
};
export const toggleTestingForm = () => {
    return (dispatch, getState) => {
        dispatch(showTestingForm(!getState().testingForm))
    }
};
export const toggleNavigation = (value) => {
    return (dispatch, getState) => {
        dispatch(navigateTogle(value))
    }
};


export default (state = initState, action) => {
    switch (action.type) {
        case SHOW_JASA:
            return {...state, testingShow: action.id};
            case SHOW_TESTING_FORM:
            return {...state, testingForm: action.id};
            case TOGGLE_NAVIGATE:
            return {...state, navigateComponent: action.id};
        default:
            return state;
    }
}

