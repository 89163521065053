import React from 'react'
import './styles/TestingFormContent.css'

class TestingFromContent extends React.Component {
    componentDidMount() {
    }

    state = {
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        message: '',
        sizeLeft: 'Left',
        sizeRight: 'Right',
        toe: null,
        contactType: '',
        emailError: '',
        nameError: null,
        messageError: '',
        sizeError: '',
        toeError: '',
        canSubmit: false,
        error: null,
        submitted: false,
        success: false,
     };

     encode = data =>
    Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    validateInputs = () => {
        const { firstname, email, message, toe, sizeLeft, sizeRight } = this.state
        let canSubmit = false;
        let nameCheck = false;
        let emailCheck = false;
        let messageCheck = false;
        let toeCheck = false;
        let sizeCheck = false;

        if(firstname.length > 2){
            nameCheck = true
            this.setState({ nameError: null })
        } else {
            this.setState({ nameError: 'Please make sure your name is correct '})
        }

        var re = /\S+@\S+\.\S+/
        if (re.test(email)) {
            emailCheck = true
            this.setState({ emailError: null })
            } else {
            this.setState({ emailError: 'Epost er ikke gyldig' })
        }

        if (message.length > 1) {
            messageCheck = true
            this.setState({ messageError: null })
          } else {
            this.setState({ messageError: 'Det mangler en beskrivelse' })
          }
        
        if(sizeLeft || sizeRight){
            sizeCheck = true;
        } else{
            this.setState({ sizeError: 'Select a size '})
        }
        if(toe){
            toeCheck = true
        } else {
            this.setState({ toeError: "Select a toe "})
        }
        if(nameCheck && emailCheck && messageCheck && toeCheck && sizeCheck){
           return(canSubmit = true)
        } else {
            return (canSubmit = false)
        }

    }
    
    handleSubmit = e => {
        e.preventDefault();
        const canSubmit = this.validateInputs();

        if(canSubmit){
            const data = {
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
                message: this.state.message,
                phoneNumber: this.state.phoneNumber,
                contactType: this.state.contactType,
                sockSize: `Left size ${this.state.sizeLeft} Right size ${this.state.sizeRight}`,
                toe: this.state.toe
            }
            fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: this.encode({ 'form-name': 'Testing-Form', ...data })
              })
              .then(response => {
                  this.setState({
                      success: true,
                      submitted: true,
                      
                  })
                  //this.resetForm()
              })
              .catch(error => {
                  console.log(error)
                  this.setState({
                      success: false,
                      submitted: true,
                      message: 'Noe gikk galt. Vennligst forsøk igjen.'
                  })
              })
        }
       
    }
    resetForm = () => {
        this.setState({
          firstname: '',
          lastname: '',
          phoneNumber: '',
          email: '',
          message: '',
          canSubmit: false,
          submitted: false,
          toe: null
        })
      }

    render() { 
        const {
            firstname,
            lastname,
            email,
            phoneNumber,
            message,
            emailError,
            nameError,
            messageError,
            sizeError,
            toeError,
            canSubmit,
            error,
            submitted,
            success,
        } = this.state;
        if (!this.state.submitted){
        return ( 
            <div className = "testing-form-main">
                <form
                className = "testing-form"
                name = "contactForm"
                onSubmit = {this.handleSubmit}
                data-netlify = "true"
                method = "POST"
                netlify = "true"
                >
                {nameError && (
                    <p className = "error">{nameError}</p>
                )}
                <input 
                type = "text" 
                placeholder = "Fornavan"
                name = "firstname"
                value = {firstname}
                onChange = {this.handleChange}
                />
                <input 
                type = "text" 
                placeholder = "Etternavn"
                name = "lastname"
                value = {lastname}
                onChange = {this.handleChange}
                />
                {emailError && (
                    <p className = "error" >{ emailError }</p>
                )}
                <input 
                type = "email" 
                placeholder = "E-post"
                name = "email"
                value = {email}
                onChange = {this.handleChange}
                />
                <input 
                type = "number" 
                placeholder = "Telefon"
                name = "phoneNumber"
                value = {phoneNumber} 
                onChange = {this.handleChange}
                />
                {messageError && (
                    <p className = "error" >{ messageError }</p>
                )}
                <textarea 
                placeholder = "Skriv meldingen din her"
                name = "message"
                value = {message}
                onChange = {this.handleChange}
                />
                <div className = "size-div">
                    {sizeError && (<p> { sizeError } </p>)}
                    <label>Størrelse</label>
                    <label>
                        Venstre
                    <select name = "sizeLeft" defaultValue = {this.state.sizeLeft} onChange = {this.handleChange}>
                        <option value="None">None</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                    </select>
                    </label>
                    <label>
                        Høyre
                        <select name = "sizeRight" value = {this.state.sizeRight} onChange = {this.handleChange}>
                            <option value="None">None</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                        </select>
                    </label>
                    <br/>
                    <label>Ønsker du strømpen med eller uten tå</label>
                    {toeError && (<p>{ toeError }</p>)}
                    <label>
                        <input 
                        name = "toe"
                        type = "radio"
                        value = "Med tå"
                        onChange = {this.handleChange}
                        />
                        Med tå
                    </label>
                    
                    <label>
                    <input 
                        name = "toe"
                        type = "radio"
                        value = "Uten tå"
                        onChange = {this.handleChange}
                        />
                        Uten tå
                    </label>
                </div>
                <button
                type = "submit"  
                onClick = {this.handleSubmit}
                >
                    Send
                </button>
            </form>
        </div>
         )}else {
        return(
            <div className={'submitted-message'}>
                Takk, du hører snart fra oss.
            </div>)}
    }
}
 
export default TestingFromContent;